import { Link } from 'react-router-dom'
import styles from './layout.module.scss'

export default function Stylist() {
  return (
    <div className={styles.stylist}>
      <div className={styles.container}>
        <h2 id="/stylist">Я – СТИЛИСТ</h2>
        <p>Я – дипломированный «художник по костюмам». С радостью помогу вам разобрать ваш гардероб и подберу луки на все случаи жизни!</p>
        <div className={styles.cards}>
          <div className={styles.card}>
            <h4>ОНЛАЙН</h4>
            <div className={styles.description}>
              <div>Во время видео-звонка помогу вам разобрать ваш гердероб и составить капсулы из
                того, что есть.</div>
              <div>Подскажу, что докупить, скину ссылки на подходящую одежду и аксессуары.</div>
              <div>В течение недели после звонка буду готова ответить на возникшие у вас вопросы.</div>

            </div>
            <div className={styles.actions}>
              <div className="cost">от <b>3 000 руб</b>.</div>
              <Link to="/online" className={styles.button}>ПОДРОБНЕЕ</Link>
            </div>
          </div>
          <div className={styles.card}>
            <h4>ОФФЛАЙН</h4>
            <div className={styles.description}>
              <div>Услуги стилиста в Санкт-Петербурге у вас дома! Всё то же самое, что и онлайн, но при личной встрече!</div>
            </div>
            <div className={styles.actions}>
              <div className="cost">от <b>8 000 руб</b>.</div>
              <Link to="/offline" className={styles.button}>ПОДРОБНЕЕ</Link>
            </div>
          </div>
          <div className={styles.card}>
            <h4>ШОППИНГ</h4>
            <div className={styles.description}>
              Проведу шоппинг-сопровождение в Санкт-Петербурге. Обойдём ваши любимые магазины, винтажки и секонды, и подберём идеальные для вас сочетания одежды и аксессуаров.
            </div>
            <div className={styles.actions}>
              <div className="cost">от <b>12 000 руб</b>.</div>
              <Link to="/shopping" className={styles.button}>ПОДРОБНЕЕ</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}