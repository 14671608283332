import { useEffect, useRef } from 'react';

export default function SavadogoSVG(props: {radius: number, width: number, dotradius: number, color: string, dotcolor: string}) {
  const size = props.radius;
  const width = props.width;
  const w = width/size;
  const dotsize = props.dotradius;
  const color = props.color;
  const dotcolor = props.dotcolor;
  const r = (r: number) => {
    return size + r * width / 2;
  }
  const f = (a: number, r: number) => {
    const f = f2(a, r);
    return `${f[0]} ${f[1]}`;
  }
  const f2 = (a: number, r: number) => {
    const angle = Math.PI / 4 * a;
    const radius = size + r * width / 2;
    return [Math.cos(angle) * radius, Math.sin(angle) * radius];
  }
  const x = (p: number) => {
    const a = Math.acos(width/size*p);
    const c = Math.cos(a) * size;
    return c;
  };
  const xc = (p: number, r: number) => {
    const radius = size + r * width / 2;
    const m = radius/size;
    const a = Math.acos(p*m);
    const c = Math.cos(a) * radius;
    return c;
  };
  const xs = (p: number, r: number) => {
    const radius = size + r * width / 2;
    const m = radius/size;
    const a = Math.asin(p*m);
    const c = Math.sin(a) * radius;
    return c;
  };
  const dx = (p: number, r: number) => {
    const radius = size + r * width / 2;
    return Math.sin(Math.acos(p/radius))*radius;
  }

  let dot = <path d={`M 0 -${dotsize} A ${dotsize} ${dotsize} 0 0 0 0 ${dotsize} A ${dotsize} ${dotsize} 0 0 0 0 -${dotsize} Z`} stroke="none" fill={dotcolor} />;
  if (dotsize < 1) {
    dot = <></>;
  }

  let ledsize = width / 2;
  let led = `M 0 -${ledsize} A ${ledsize} ${ledsize} 0 0 0 0 ${ledsize} A ${ledsize} ${ledsize} 0 0 0 0 -${ledsize} Z`
  let o = size / 5;
  let s = width / 2;

  let colors = [];
  for (let i = 0; i < 100; i++) {
    colors[i] = i % 2 == 0 ? '#ffffff' : '#ffcccc';
  }

  const svgRef = useRef<SVGSVGElement>(null);

  const leds_def: any = {
    's': [
      { offset: 260, count: 4, space: 17 },
      { offset: 465, count: 4, space: 18 },
      { offset: 680, count: 5, space: 16.4 },
      { offset: 930, count: 1, space: 18 },
      { offset: 40, count: 4, space: 18 },
    ],
    'a1': [
      { offset: 550, count: 3, space: 16 },
      { offset: 250, count: 5, space: 17.8, reverse: true },
      { offset: 520, count: 5, space: 17.8, reverse: true },
    ]
  };

  useEffect(() => {
    if (!svgRef.current) {
      return;
    }
  
    const svgLeds = svgRef.current.querySelectorAll('path[data-type="led"]');
    svgLeds.forEach(l => l.remove());
    if (true) {
      return;
    }

    var led_idx = 0;
    for (const led_letter in leds_def) {
      const letter = svgRef.current!.querySelector('path[data-letter="' +led_letter+ '"]') as SVGGeometryElement;
      const led_arr = leds_def[led_letter];
      for (const led_def of led_arr) {

        for (let j = 0; j < led_def.count; j++) {
          for (let k = 0; k < 3; k++) {
            let pos = led_def.offset + (j * 3 + k) * led_def.space * (led_def.reverse ? -1 : 1);
            while (pos < 0) {
              pos += letter.getTotalLength();
            }
            if (pos > letter.getTotalLength()) {
              pos %= letter.getTotalLength();
            }
            const p = letter.getPointAtLength(pos);
            
  
            const l = document.createElementNS(svgRef.current!.namespaceURI, 'path') as SVGPathElement;
            l.setAttributeNS(null, 'data-type', 'led');
            l.setAttributeNS(null, 'd', led);
            l.setAttributeNS(null, 'fill', led_idx % 2 == 0 ? '#ffffff' : '#ff8888');
            l.setAttributeNS(null, 'transform', `translate(${p.x}, ${p.y})`);
            letter.after(l);
          }
          led_idx++;
        }

      }
    }

    // const letters = svgRef.current.querySelectorAll('.letter');
    // for (let i = 0; i < letters.length; i++) {
    //   const letter = letters.item(i) as SVGGeometryElement;
    //   console.log('letter', letter, letter.getPointAtLength(1));

    //   for (let j = 0; j < 15; j++) {
    //     for (let k = 0; k < 3; k++) {
    //       const p = letter.getPointAtLength(270 + (j * 3 + k) * 15);

    //       const l = document.createElementNS(svgRef.current.namespaceURI, 'path') as SVGPathElement;
    //       l.setAttributeNS(null, 'data-type', 'led');
    //       l.setAttributeNS(null, 'd', led);
    //       l.setAttributeNS(null, 'fill', '#ffffff');
    //       l.setAttributeNS(null, 'transform', `translate(${p.x}, ${p.y})`);
    //       letter.after(l);
    //     }
    //   }
    //   // svgRef.current.app(l);
    // }
  }, [svgRef]);

  return (
    <svg ref={svgRef} style={{maxWidth: '100%', height: 'auto'}} width={(size*6*2+width*11)} height={(size*2+width)} viewBox={`0 0 ${size*6*2+width*11} ${size*2+width}`}>
<g transform={`translate(${size*1+width*1-width*0.5},${size+width/2})`}>
    {dot}

    <path className='letter' data-letter="s" d={`
    M ${f(7,1)}
    A ${r(1)} ${r(1)} 0 0 0 ${f(4,1)}
    L -${r(1)} ${width/2}
    L ${r(-1)} ${width/2}
    A ${r(-1)} ${r(-1)} 0 0 1 ${f(3,-1)}
    L ${f(3,1)}
    A ${r(1)} ${r(1)} 0 0 0 ${f(0,1)}
    L ${r(1)} -${width/2}
    L -${r(-1)} -${width/2}
    A ${r(-1)} ${r(-1)} 0 0 1  ${f(7,-1)}
    Z
    `} fill={color} stroke="none" />

    {/* <path className="led" transform={`translate( ${o}, ${s})`} d={led} fill={colors[0]} />
    <path className="led" transform={`translate(${o*2}, ${s})`} d={led} fill={colors[0]} />
    <path className="led" transform={`translate(${o*3}, ${s})`} d={led} fill={colors[0]} />

    <path className="led" transform={`translate(${o*4}, ${s})`} d={led} fill={colors[1]} />
    <path className="led" transform={`translate(${o*3.95}, ${s + o})`} d={led} fill={colors[1]} />
    <path className="led" transform={`translate(${o*3.5}, ${s + o * 1.9})`} d={led} fill={colors[1]} />

    <path className="led" transform={`translate(${o*2.8}, ${s + o * 2.6})`} d={led} fill={colors[2]} />
    <path className="led" transform={`translate(${o*1.9}, ${s + o * 3.15})`} d={led} fill={colors[2]} />
    <path className="led" transform={`translate(${o*0.9}, ${s + o * 3.55})`} d={led} fill={colors[2]} />

    <path className="led" transform={`translate(${o*-0.2}, ${s + o * 3.6})`} d={led} fill={colors[3]} />
    <path className="led" transform={`translate(${o*-1.2}, ${s + o * 3.4})`} d={led} fill={colors[3]} />
    <path className="led" transform={`translate(${o*-2.2}, ${s + o * 2.95})`} d={led} fill={colors[3]} /> */}
</g>

<g transform={`translate(${size*3+width*3-width*0.5},${size+width*0.5})`}>
{dot}
<path data-letter="a1" d={`
    M -${r(1)} ${r(1)}
    L -${r(1)} 0
    A ${r(1)} ${r(1)} 0 0 1 -${width/2} -${dx(-width/2, 1)}
    L -${width/2} ${r(1)}
    L -${width*1.5} ${r(1)}
    L -${width*1.5} ${width/2}
    L -${r(-1)} ${width/2}
    L -${r(-1)} ${r(1)}
    Z
    M -${width*1.5} -${width/2}
    L -${width*1.5} -${dx(width*1.5,-1)}
    A ${r(-1)} ${r(-1)} 0 0 0 -${dx(width/2, -1)} -${width/2}
    Z
    
    `} fill={color} stroke="none" />

<path d={`
    M ${width/2} -${r(1)}
    L ${width*1.5} -${r(1)}
    L ${width*1.5} ${dx(width*1.5,-1)}
    A ${r(-1)} ${r(-1)} 0 0 0 ${r(-1)} 0
    L ${r(-1)} -${r(1)}
    L ${r(1)} -${r(1)}
    L ${r(1)} 0
    A ${r(1)} ${r(1)} 0 0 1 ${width*0.5} ${dx(width*0.5, 1)}
    Z
    
    `} fill={color} stroke="none" />
</g>

<g transform={`translate(${size*5+width*5-width*0.5},${size+width*0.5})`}>
{dot}
<path d={`
    M -${r(1)} ${r(1)}
    L -${r(1)} 0
    A ${r(1)} ${r(1)} 0 0 1 -${width/2} -${dx(-width/2, 1)}
    L -${width/2} ${r(1)}
    L -${width*1.5} ${r(1)}
    L -${width*1.5} ${width/2}
    L -${r(-1)} ${width/2}
    L -${r(-1)} ${r(1)}
    Z
    M -${width*1.5} -${width/2}
    L -${width*1.5} -${dx(width*1.5,-1)}
    A ${r(-1)} ${r(-1)} 0 0 0 -${dx(width/2, -1)} -${width/2}
    Z
    
    `} fill={color} stroke="none" />

<path d={`
    M ${width/2} -${r(1)}
    A ${r(1)} ${r(1)} 0 0 1 ${width*0.5} ${dx(width*0.5, 1)}
    Z
    M ${width*1.5} ${dx(width*1.5, -1)}
    A ${r(-1)} ${r(-1)} 0 0 0 ${width*1.5} -${dx(width*1.5, -1)}
    Z
    
    `} stroke="none" fill={color} />
</g>

<g transform={`translate(${size*7+width*7-width*0.5},${size+width*0.5})`}>
{dot}
  <path d={`
    M 0 -${r(1)} 
    A ${r(1)} ${r(1)} 0 0 0 0 ${r(1)} 
    A ${r(1)} ${r(1)} 0 0 0 0 -${r(1)} 
    Z
    M 0 -${r(-1)} 
    A ${r(-1)} ${r(-1)} 0 0 1 0 ${r(-1)} 
    A ${r(-1)} ${r(-1)} 0 0 1 0 -${r(-1)} 
    Z
  `} stroke="none" fill={color} />
</g>

<g transform={`translate(${size*9+width*9-width*0.5},${size+width*0.5})`}>
{dot}
    <path d={`
    M ${f(7,1)}
    A ${r(1)} ${r(1)} 0 1 0 ${dx(width*0.5,1)} -${width*0.5}
    L 0 -${width*0.5}
    L 0 ${width*0.5}
    L ${dx(width*0.5,-1)} ${width*0.5}
    A ${r(-1)} ${r(-1)} 0 1 1 ${f(7,-1)}
    Z
    `} stroke="none" fill={color} />
</g>

<g transform={`translate(${size*11+width*11-width*0.5},${size+width*0.5})`}>
{dot}
  <path d={`
    M 0 -${r(1)} 
    A ${r(1)} ${r(1)} 0 0 0 0 ${r(1)} 
    A ${r(1)} ${r(1)} 0 0 0 0 -${r(1)} 
    Z
    M 0 -${r(-1)} 
    A ${r(-1)} ${r(-1)} 0 0 1 0 ${r(-1)} 
    A ${r(-1)} ${r(-1)} 0 0 1 0 -${r(-1)} 
    Z
  `} stroke="none" fill={color} />
</g>

    </svg>
  )
}