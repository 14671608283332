export default function Offline() {
  return (
    <div>
      <h2>РАЗБОР ГАРДЕРОБА В САНКТ-ПЕТЕРБУРГЕ</h2>
            <h4>Что это?</h4>
            <p>Это когда профи своего дела, то есть, я, приходит к вам домой и перебирает всю одежду, обувь и аксессуары, чтобы определить, от чего нужно избавиться, что еще можно носить, а что нужно докупить. В идеале прийти к тому, чтобы получился гардероб, где все вещи будут носиться, сочетаться и соответствовать вашему образу жизни!</p>
            <h4>Кому это надо?</h4>
            <p>Разбор гардероба подойдет: блогерам, художникам, людям, которые давно не обновляли свой гардероб, которые сомневаются в актуальности своих вещей, кому лень разбираться в моде, трендах, а хочется стильно выглядеть. Тем, кому захотелось добавить чего-то нового/трендов в свой базовый гардероб, кто не умеет собирать базовый гардероб, не знает, что ему подходит или переехал в другую страну, где совершенной другой климат, а подходящей одежды нет…</p>
            <h4>Как происходит?</h4>
            <p>Если разбор происходит <a href="#online">онлайн</a>, вы заранее делаете фото/видео вашего гардероба (включая обувь и аксессуары), затем мы созваниваемся в удобное время, чтобы обсудить дальнейшие действия: что можно оставить, что уже морально устарело и необходимо заменить.</p>
            <p>Для офлайн разбора достаточно договориться, когда вам будет удобно и я приеду в назначенный день.</p>
            <p>Разбирать будем абсолютно всё: одежда верхняя, летняя, осенняя, зимняя, обувь, аксессуары…</p>
            <p>Моя задача – научить вас самостоятельно собирать те образы, которые подходят именно вам и научить правильному шопингу, без лишних затрат.</p>
            <p>В течении недели после созвона я буду готова ответить на любые ваши вопросы.</p>
            <p>Кроме того, я могу сделать презентацию с индивидуальными капсулами для вас.</p>
            <h4>Сколько стоит?</h4>
            <p>Разбор гардероба в Санкт-Петербурге – <b className="cost">8 000 рублей</b>.</p>
            <p>Разбор гардероба + презентация с индивидуальными капсулами – <b className="cost">12 000 рублей</b>.</p>
            <h4>Как записаться?</h4>
            <p>Просто заполните бриф, и я с вами свяжусь!</p>
            <p><a href="https://forms.gle/doznpmRXyxisXZpV7" rel="noreferrer" target="_blank" className="button">БРИФ ДЛЯ ДЕВУШЕК</a></p>
    </div>
  )
}
