import styles from './header.module.scss'

export default function Header() {
  return (
    <section className={styles.header}>
      <h1>Привет, я Глаша!</h1>
      <div className={styles.links}>
          <a className={styles.stylist} href="#/stylist">Я – СТИЛИСТ</a>
          <a className={styles.model} href="#/model">Я – МОДЕЛЬ</a>
          <a className={styles.ambassador} href="#/ambassador">Я – АМБАССАДОР</a>
      </div>
    </section>
  )
}
