import styles from './layout.module.scss'
import {ReactComponent as Instagram} from '../icons/instagram.svg'
import {ReactComponent as Youtube} from '../icons/youtube.svg'
import {ReactComponent as Telegram} from '../icons/telegram2.svg'
import { Link } from 'react-router-dom'

export default function Ambassador() {
  return (
    <div className={styles.ambassador}>
      <div className={styles.container}>
        <h2 id="/ambassador">Я – АМБАССАДОР</h2>
        <p>
          Я активно веду социальные сети и обожаю придумывать креативный и оригинальный контент!
          С удовольствием прорекламирую вашу одежду или аксессуары, и стану вашим лицом, если у нас случился матч! Я проработаю идею, сниму, смонтирую, и опубликую в своих соцсетях; а всё, что требуется от вас – любоваться результатом и радоваться новым клиентам!
        </p>
        <div>
          <div className={styles.social}>
            <a href="https://instagram.com/ziwendys" rel="noreferrer" target="_blank"><Instagram />ZIWENDYS</a>
          </div>
          <div className={styles.social}>
            <a href="https://www.youtube.com/@onlystilno" rel="noreferrer" target="_blank"><Youtube />ГЛАША САВАДОГО</a>
          </div>
          <div className={styles.social}>
            <a href="https://t.me/savadosha" rel="noreferrer" target="_blank"><Telegram />САВАДОША</a>
          </div>
          
          <Link to="/collab" className={styles.button}>СОТРУДНИЧЕСТВО</Link>
        </div>
      </div>
    </div>
  )
}