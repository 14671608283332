import styles from './footer.module.scss'
import {ReactComponent as SavadogoLogo} from '../icons/savadogo-logo.svg'

export default function Footer() {
  return (
    <section className={styles.footer}>
        <div className={styles.container}>
            <div>
                Что-то ещё?
            </div>
            <div>
                Свяжитесь со мной в <a href="https://instagram.com/ziwendys_pr" rel="noreferrer" target="_blank">Instagram</a> или <a href="https://t.me/ziwendys" rel="noreferrer" target="_blank">Telegram</a>.
            </div>
            <div className={styles.logo}>
              <SavadogoLogo  />
            </div>
        </div>
    </section>
  )
}
