import Colorful from '@uiw/react-color-colorful';
import SavadogoSVG from '../../components/savadogo';
import { useState } from 'react';
import Slider from 'rc-slider' ;   
import 'rc-slider/assets/index.css' ; 
import {ReactComponent as SavadogoSVGStatic} from '../../components/savadogo.svg';

export default function LogoPlayground() {
  const [hex, setHex] = useState('#ff0000');
  const [dothex, setDotHex] = useState('#883333');
  const [width, setWidth] = useState(14);
  const [radius, setRadius] = useState((150-14)/2);
  const [dotradius, setDotradius] = useState(0);

  return (
    <div>
      <div  style={{display: 'flex', gap: '40px'}}>
      <div>
        <Colorful color={hex} onChange={c => setHex(c.hex)} disableAlpha />
        <Slider min={1} max={30} value={width} onChange={c => setWidth(c.valueOf() as number)} />
        <Slider min={10} max={100} value={radius} onChange={c => setRadius(c.valueOf() as number)} />
      </div>
      <div>
        <Colorful color={dothex} onChange={c => setDotHex(c.hex)} disableAlpha />
        <Slider min={0} max={100} value={dotradius} onChange={c => setDotradius(c.valueOf() as number)} />
      </div>
      </div>
      

      
      

      <SavadogoSVG radius={radius} width={width} dotradius={dotradius} color={hex} dotcolor={dothex} />

      <div>
      <SavadogoSVGStatic />
      </div>
    </div>
  )
}
