import { useNavigate } from 'react-router-dom'
import styles from './popup.module.scss'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffect, useRef } from 'react';

export default function Popup(props: {element?: React.ReactNode}) {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  const closePopup = () => {
    navigate('/');
  }

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    const observe = modalRef.current;
    disableBodyScroll(observe);
    return () => {
      enableBodyScroll(observe);
    }
  }, []);

  return (
    <div className={styles.popup} ref={modalRef}>
      <button className={styles.close} onClick={closePopup}>✕</button>
      {props.element}
    </div>
  )
}
