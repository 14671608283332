import { Route, Routes, useLocation } from 'react-router-dom';
import Ambassador from './ambassador';
import Footer from './footer';
import Header from './header';
import styles from './layout.module.scss'
import Model from './model';
import Stylist from './stylist';
import { useEffect } from 'react';
import Online from './modals/online';
import Popup from './modals/popup';
import Offline from './modals/offline';
import Shopping from './modals/shopping';
import InstagramCollab from './modals/instagram';
import Invite from './modals/invite';
import Collab from './modals/collab';
import LogoPlayground from './modals/logo';
// import LedPlayground from './modals/led';

export default function Layout() {
  const location = useLocation();
  useEffect(() => {
    console.log('location', location)
  //   if (location.hash) {
  //     const el = document.getElementById(location.hash);
  //     if (el) {
  //       el.scrollIntoView({behavior: 'smooth', block: 'start'})
  //     }
  //   }
  }, [location]);

  return (
    <>
      <Header />
      <section className={styles.blocks}>
        <Stylist />
        <Model />
        <Ambassador />
      </section>
      <Footer />
      <Routes>
        <Route path="/" element={<></>} />
        <Route path="/online" element={<Popup element={<Online />} />} />
        <Route path="/offline" element={<Popup element={<Offline />} />} />
        <Route path="/shopping" element={<Popup element={<Shopping />} />} />
        <Route path="/invite" element={<Popup element={<Invite />} />} />
        <Route path="/instagram-collab" element={<Popup element={<InstagramCollab />} />} />
        <Route path="/collab" element={<Popup element={<Collab />} />} />
        <Route path="/logo-playground" element={<Popup element={<LogoPlayground />} />} />
        {/* <Route path="/led-playground" element={<Popup element={<LedPlayground />} />} /> */}
      </Routes>
    </>
  )
}

