import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactDOMServer from "react-dom/server";
import './index.scss';
import Layout from './views/layout';
import { HashRouter } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';

const bootstrap = <React.StrictMode>
  <HashRouter>
    <Layout />
  </HashRouter>
</React.StrictMode>;

function createRoot() {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(bootstrap);
}


function hydrateRoot() {
  ReactDOM.hydrateRoot(document.getElementById('root') as HTMLElement, bootstrap);
}

function generateSSR() {
  const bootstrap = <React.StrictMode>
  <StaticRouter location="/">
    <Layout />
  </StaticRouter>
</React.StrictMode>;

  const html = ReactDOMServer.renderToStaticMarkup(bootstrap);
  console.log(html);
}

createRoot();
// hydrateRoot();
// generateSSR();
