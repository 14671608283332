export default function Shopping() {
  return (
    <div>
           <h2>ШОППИНГ-СОПРОВОЖДЕНИЕ В САНКТ-ПЕТЕРБУРГЕ</h2>
            <h4>Как проходит?</h4>
            <p>Перед шоппингом я провожу имидж-консультацию (оффлайн/онлайн), где обсуждаем пожелания клиента, ставим цель сотрудничества, определяем бюджет и составляем список покупок.</p>
            <p>Я заранее пройдусь по магазинам и подберу подходящую одежду исходя из ваших пожеланий, и продумаю маршрут. В день шоппинга встречаемся в магазине или шоу-руме, уже зная, что мы будем искать.</p>
            <p>Цель шоппинга: подобрать подходящие вещи, которые будут радовать душу.</p>
            <p>
                В итоге вы получаете:
                <ul>
                    <li>сэкономленное время;</li>
                    <li>хорошее настроение;</li>
                    <li>новый имидж;</li>
                    <li>стильные, прекрасно сочетающиеся вещи;</li>
                    <li>возможность обращаться ко мне, если будут какие-то сомнения уже после нашей совместной работы.</li>
                </ul>
            </p>
            <h4>Сколько стоит?</h4>
            <p><b>3 часа</b> шоппинг-сопровождениия – <b className="cost">12 000 рублей</b>. Каждый дополнительный час – <b className="cost">3 000 рублей</b>.</p>
            <h4>Как записаться?</h4>
            <p>Свяжитесь со мной в <a href="https://instagram.com/ziwendys" rel="noreferrer" target="_blank">Instagram</a> или <a href="https://t.me/ziwendys" rel="noreferrer" target="_blank">Telegram</a>, и мы обо всём договоримся.</p>
    </div>
  )
}
