export default function InstagramCollab() {
  return (
    <div>
      <h2>СОТРУДНИЧЕСТВО</h2>
      <h4>Reels</h4>
      <p>Я обожаю придумывать и снимать короткие и креативные видео. Если у вас есть продукт, который откликается в моём сердечке и подходит по тематике моему блогу, то я буду рада снять для вас Reels!</p>
      <p>Стоимость одного видео – <b>от 3500 рублей</b>. В базовую сумму входит <b>проработка идеи, простая съёмка, простой монтаж и публикация</b> в моём Instagram.</p>
      <p>Для реализации некоторых идей может потребоваться аренда студии, оборудования, реквизита, привлечение оператора и монтажёра – в этом случае стоимость согласовывается отдельно.</p>
      <h4>Процесс сотрудничества:</h4>
      <ul>
        <li>обсуждаем с вами идею видео, его стоимость и предварительную дату публикации;</li>
        <li>вы вносите предоплату 50% и отправляете мне ваш продукт;</li>
        <li>после предоплаты и доставки продукта солгасовываем окончательную дату публикации;</li>
        <li>я снимаю, монитрую и публикую видео в своём аккаунте в согласованную дату;</li>
        <li>вы оплачиваете оставшиеся 50%;</li>
        <li>при необходимости я возвращаю вам ваш продукт.</li>
      </ul>
      <p>По вашему желанию я могу указать ваш аккаунт в качестве соавтора в Instagram. Использование (перезалив) видео в других аккаунтах, соцсетях, как и использование в любыых иных целях, возможно только с моего согласия.</p>

      <p>Сотрудничество <b>по бартеру</b> возможно в исключительных случаях, когда ваш продукт мне действительно нужен.</p>

      <h4>Stories</h4>
      <p>Сторителл (до 3 сториз) о вашем продукте – 1000 рублей за сториз.</p>
    </div>
  )
}
