import styles from './layout.module.scss'
import {Swiper, SwiperSlide} from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { Link } from 'react-router-dom';


export default function Model() {
  return (
    <div className={styles.model}>
      <div className={styles.container}>
        <h2 id="/model">Я – МОДЕЛЬ</h2>
        <div className={styles.photos}>
          <Slideshow />
          <div className={styles.photosOverlay}>
            <p>Я постоянно участвую в твореческих съёмках, люблю экспериментировать с образами, интересными локациями. Всегда рада сотрудничеству с фотографами и брендами, как на коммерческой основе, так и TFP!</p>
            <Link to="/invite" className={styles.button}>ПРИГЛАСИТЬ НА СЪËМКУ</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

function Slideshow() {
  return (
  <Swiper className={styles.photosContainer} loop={true} modules={[Autoplay]} autoplay={{delay: 2500}}>
    <SwiperSlide><img src="/photos/1_crop2.jpg" alt="" /></SwiperSlide>
    <SwiperSlide><img src="/photos/2_crop2.jpg" alt="" /></SwiperSlide>
    <SwiperSlide><img src="/photos/3_crop2.jpg" alt="" /></SwiperSlide>
    <SwiperSlide><img src="/photos/4_crop2.jpg" alt="" /></SwiperSlide>
    <SwiperSlide><img src="/photos/5_crop2.jpg" alt="" /></SwiperSlide>
    <SwiperSlide><img src="/photos/6_crop2.jpg" alt="" /></SwiperSlide>
    <SwiperSlide><img src="/photos/7_crop2.jpg" alt="" /></SwiperSlide>
    <SwiperSlide><img src="/photos/8_crop2.jpg" alt="" /></SwiperSlide>
    <SwiperSlide><img src="/photos/9_crop2.jpg" alt="" /></SwiperSlide>
    <SwiperSlide><img src="/photos/10_crop2.jpg" alt="" /></SwiperSlide>
    <SwiperSlide><img src="/photos/11_crop2.jpg" alt="" /></SwiperSlide>
    <SwiperSlide><img src="/photos/12_crop2.jpg" alt="" /></SwiperSlide>
    <SwiperSlide><img src="/photos/13_crop2.jpg" alt="" /></SwiperSlide>
    <SwiperSlide><img src="/photos/14_crop2.jpg" alt="" /></SwiperSlide>
  </Swiper>
  );
}

// function StaticPhoto() {
//   return (
//   <div className={styles.photosContainer}>
//     <img src="/photos/1_crop2.jpg" alt="" />
//   </div>
//   );
// }